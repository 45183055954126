import { Coordinates } from '@bufteam/cfacorp_delivery.bufbuild_es/cfa/delivery/core/v1/coordinates_pb';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { booleanPointInPolygon, point } from '@turf/turf';
import differenceWith from 'lodash.differencewith';
import { AreaType } from '@bufteam/cfacorp_delivery.bufbuild_es/cfa/delivery/area/v1/area_service_pb';
import { getCart } from '@cfacorp/ctrl-platform-ui-core-utils';
import { createRoot } from 'react-dom/client';
import { Button } from 'cfa-react-components';
import { useTranslation } from 'react-i18next';
import { getLatLng } from '../containers/DeliveryArea/utils';
import { colors } from '../theme';
import { useGetAreaQueries } from './useGetAreaQueries';
import { getCoordinates, getTurfPolygon } from './utils';
import { useGetStoreNumber } from './useGetStoreNumber';

/**
 * This is the most vital piece of the entire repo
 * If there is a bug with any of the points or something is not working as expecting, then check:
 *
 * - set_at and insert_at events first in handlePointEvents:
 *   -- console.log() to see handlePointEvents is called
 *   -- if not, then map has lost reference to draftPolygon's event and will need to be reset
 *
 * - if you introduced a useCallback or useMemo:
 *   -- try to use without, caching tends to cause conflicts with Google Maps
 *
 * - not seeing anything happen:
 *   -- check that all of these have values: map, draftCoordinates, maxAreaCoordinates, draftPolygon
 *
 * - seeing multiple blue polygons:
 *   -- check the if statement in the useEffect calling (queryCurrent(), queryDraft())
 *   -- should check for: map && !draftCoordinates?.length && maxAreaCoordinates?.length
 *   -- likely one of these got removed or their value is not updating
 *
 *   NOTES:
 *   - any time draftCoordinates is [], the queries will be called and the polygon will be removed and recreated
 **/
export const useDraftArea = ({
  maxAreaCoordinates,
  map,
}: {
  maxAreaCoordinates?: Coordinates[];
  map: google.maps.Map | null;
}) => {
  const { queryAreas } = useGetAreaQueries();
  const { t } = useTranslation();

  const storeNumber = useGetStoreNumber();
  const [draftPolygon, setDraftPolygon] = useState<google.maps.Polygon | null>(
    null,
  );
  const [draftCoordinates, setDraftCoordinates] = useState<Coordinates[]>([]);
  const [isCurrent, setIsCurrent] = useState<boolean>(false);

  const getInfoWindowContainer = useCallback(
    (onClickHandler: () => void) => {
      const container = document.createElement('div');
      const root = createRoot(container);
      root.render(
        <div>
          <Button onClick={onClickHandler} size="sm">
            {t('DeliveryArea.deleteInfoWindow')}
          </Button>
        </div>,
      );
      return container;
    },
    [t],
  );
  const infoWindow = useMemo(() => new google.maps.InfoWindow(), []);

  const isWithinMaxArea = useCallback(
    (ll: google.maps.LatLng): boolean => {
      if (!maxAreaCoordinates?.length) {
        return false;
      }
      return booleanPointInPolygon(
        point([ll.lng(), ll.lat()]),
        getTurfPolygon(maxAreaCoordinates),
      );
    },
    [maxAreaCoordinates],
  );

  const updateCoordinates = useCallback(
    (p: google.maps.Polygon | null) =>
      p &&
      setDraftCoordinates(
        p
          .getPath()
          .getArray()
          .map(
            (ll: google.maps.LatLng) =>
              new Coordinates({
                longitude: ll.lng(),
                latitude: ll.lat(),
              }),
          ),
      ),
    [],
  );

  // if not called, then undo button will stay at invalid point coordinates
  const resetUndoButton = useCallback(() => {
    if (draftPolygon?.setEditable) {
      draftPolygon.setEditable(false);
      draftPolygon.setEditable(true);
    }
  }, [draftPolygon]);

  // check if there is no difference between coordinates in draftCoordinates and current draftPolygon's path
  const isNoPointChange = useCallback(
    (ll?: google.maps.MVCArray<google.maps.LatLng>) =>
      !differenceWith(
        ll?.getArray().map((l: google.maps.LatLng) => ({
          latitude: l.lat(),
          longitude: l.lng(),
        })) as Coordinates[],
        draftCoordinates,
        (p, d) => p.latitude === d.latitude && p.longitude === d.longitude,
      ).length,
    [draftCoordinates],
  );

  const handlePointEvents = useCallback(
    (i: number, previousLatLng?: google.maps.LatLng) => {
      const path = draftPolygon?.getPath();
      const isNoChange = isNoPointChange(path);
      if (!path || isNoChange) {
        return;
      }
      const isValidPoint = isWithinMaxArea(path.getAt(i));
      // set_at: invalid handling
      if (!isValidPoint && previousLatLng) {
        path.setAt(i, previousLatLng);
        resetUndoButton();
        return;
      }
      // insert_at: invalid handling
      if (!isValidPoint && !previousLatLng && map) {
        path.removeAt(i);
        resetUndoButton();
        return;
      }
      updateCoordinates(draftPolygon);
      setIsCurrent(false);
    },
    [
      updateCoordinates,
      isWithinMaxArea,
      resetUndoButton,
      isNoPointChange,
      draftPolygon,
      map,
    ],
  );

  const deletePoint = useCallback(
    (e: google.maps.PolyMouseEvent) => {
      if (draftPolygon) {
        const path = draftPolygon.getPath();
        const i = path
          .getArray()
          .findIndex(
            (ll: google.maps.LatLng) =>
              ll.lat() === e.latLng?.lat() && ll.lng() === e.latLng.lng(),
          );
        if (i > -1) {
          path.removeAt(i);
          resetUndoButton();
        }
        updateCoordinates(draftPolygon);
        setIsCurrent(false);
        infoWindow.close();
      }
    },
    [draftPolygon, infoWindow, resetUndoButton, updateCoordinates],
  );

  const displayDeletion = useCallback(
    (e: google.maps.PolyMouseEvent) => {
      infoWindow.setContent(getInfoWindowContainer(() => deletePoint(e)));
      infoWindow.setPosition(e.latLng);
      infoWindow.open({ map });
    },
    [deletePoint, getInfoWindowContainer, infoWindow, map],
  );

  const initPolygon = useCallback(
    (coordinates?: Coordinates[]) => {
      const polygon = new google.maps.Polygon({
        paths: getLatLng(coordinates ?? []),
        strokeColor: colors.blueBorder,
        fillColor: colors.blueFill,
        strokeOpacity: 0.8,
        fillOpacity: 0.4,
        clickable: true,
        strokeWeight: 2,
        editable: true,
      });
      updateCoordinates(polygon);
      setDraftPolygon(polygon);
      polygon.setMap(map);
    },
    [map, updateCoordinates],
  );

  useEffect(() => {
    if (draftPolygon) {
      google.maps.event.addListener(
        draftPolygon,
        'contextmenu',
        displayDeletion,
      );
      const path = draftPolygon.getPath();
      google.maps.event.addListener(path, 'insert_at', handlePointEvents);
      google.maps.event.addListener(path, 'set_at', handlePointEvents);
    }
  }, [draftPolygon, deletePoint, handlePointEvents, displayDeletion]);

  useEffect(() => {
    if (map && !draftCoordinates?.length && maxAreaCoordinates?.length) {
      draftPolygon?.setMap(null);
      queryAreas().then(deliveryAreas => {
        if (deliveryAreas) {
          const current = getCoordinates(deliveryAreas, AreaType.CURRENT);
          const draft = getCoordinates(deliveryAreas, AreaType.DRAFT);
          const coordinates = draft?.length ? draft : current;
          setIsCurrent(!draft?.length);
          if (coordinates?.length) {
            initPolygon(coordinates);
          }
        }
      });
    }
  }, [
    maxAreaCoordinates?.length,
    draftCoordinates,
    draftPolygon,
    initPolygon,
    map,
    queryAreas,
  ]);

  const setEstimateToDraft = (e: Coordinates[]) => {
    draftPolygon?.setPaths(getLatLng(e));
    draftPolygon?.setMap(map);
  };

  const resetDraft = useCallback(() => {
    draftPolygon?.setMap(null);
    draftPolygon?.setPath([]);
    setDraftCoordinates([]);
    getCart(storeNumber);
  }, [draftPolygon, storeNumber]);

  return {
    setDraftCoordinates,
    setEstimateToDraft,
    draftCoordinates,
    setIsCurrent,
    resetDraft,
    isCurrent,
  };
};
