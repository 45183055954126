import { GetDeliveryAreasRequest } from '@bufteam/cfacorp_delivery.bufbuild_es/cfa/delivery/area/v1/area_service_pb';
import { useCallback } from 'react';
import { deliveryAreaService } from '../services';
import { useTravelTypeContext } from '../context/TravelTypeContext';
import { useGetStoreNumber } from './useGetStoreNumber';

export const useGetAreaQueries = () => {
  const locationNumber = useGetStoreNumber();
  const { travelType } = useTravelTypeContext();

  const queryAreas = useCallback(async () => {
    if (!locationNumber) {
      console.error('Location number is not defined');
      return;
    }

    try {
      const response = await deliveryAreaService.getDeliveryAreas(
        new GetDeliveryAreasRequest({
          travelType: travelType,
          locationNumber,
        }),
      );
      const { deliveryAreas } = response;
      if (!deliveryAreas) {
        console.error('Delivery Areas undefined');
        return;
      }
      return deliveryAreas;
    } catch (e) {
      console.error('GetDeliveryAreas Error', e);
      return;
    }
  }, [locationNumber, travelType]);

  return { queryAreas };
};
